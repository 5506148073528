<template>
  <div :class="computedClasses">
    <div class="checks">
      <div class="line" />
      <div
        v-if="isLast"
        class="last"
      />
      <div class="check">
        <Fa :icon="['far', 'check']" />
      </div>
    </div>
    <div class="content-wrapper">
      <div class="main">
        <slot name="title">
          <div
            v-if="label"
            class="title"
          >
            {{ label }}
          </div>
        </slot>
        <div class="content">
          <slot />
        </div>
      </div>
      <div
        v-if="$slots.actions"
        class="actions"
      >
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  isCompleted?: boolean
  isLast?: boolean
  disabled?: boolean
  label?: string
  showContentOnCompleted?: boolean
}>()

const computedClasses = computed(() => [
  'step',
  {
    'completed': props.isCompleted,
    'inactive': props.disabled,
    'hide-content-on-completed': !props.showContentOnCompleted,
  },
])
</script>

<style lang="scss" scoped>
.step {
  display: grid;
  grid-template-columns: 1.25rem 1fr;
  grid-gap: 2rem;
  align-items: flex-start;

  .checks {
    position: relative;
    height: 100%;
  }

  .content-wrapper {
    padding-bottom: 2.5rem;

    @include media-min-size(xLarge) {
      display: flex;
    }

    .main {
      flex: 1;
    }
  }

  .check {
    border-radius: 1rem;
    border: 1px solid getColor('primary-100');
    background-color: #fff;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    z-index: 2;

    svg {
      display: none;
    }
  }

  .last {
    height: 100%;
    background: #fff;
    display: block;
    position: relative;
  }

  .title {
    @include strong-1;
    color: getColor('primary-500');
    margin-bottom: 0.25rem;
  }

  .content {
    @include caption;
    color: getColor('primary-350');
  }

  &:last-child {
    .content-wrapper {
      padding-bottom: 0;
    }
  }

  &.inactive {
    .title {
      color: getColor('primary-350');
    }
  }

  &.inactive,
  &.completed.hide-content-on-completed {
    .content,
    .zbtn {
      display: none;
    }
  }

  &.completed {
    .check {
      background-color: getColor('highlight-500');
      border-color: getColor('highlight-500');
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        display: block;
        color: #fff;
        font-size: 0.75rem;
      }
    }
  }
}

.line {
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  width: 1px;
  background-color: getColor('primary-100');
  transform: translateX(-50%);
}
</style>
